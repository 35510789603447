import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../utilities/axios";
const initialState = {
  getClientStatus: null,
  clients: [],
  meta: {},
};

export const getAllClients = createAsyncThunk(
  "clients/getAllClients",
  async (body) => {
    let data;
    try {
      const response = await axios.get(
        `/websites/${body?.websiteID}/users?page=${body?.page}&limit=10`
      );
      data = response.data?.data;
      if (response.status === 200) {
        const { docs, ...meta } = data;
        return { docs, meta };
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

const slice = createSlice({
  name: "clients",
  initialState,
  reducers: {
    searchClients: (state, action) => {
      const { docs, ...meta } = action.payload;
      state.clients = docs;
      state.meta = meta;
    },
  },
  extraReducers: {
    [getAllClients.pending]: (state) => {
      state.getClientStatus = "loading";
    },
    [getAllClients.fulfilled]: (state, action) => {
      state.getClientStatus = "succeeded";
      state.clients = action.payload.docs;
      state.meta = action.payload.meta;
    },
    [getAllClients.rejected]: (state, action) => {
      state.getClientStatus = "failed";
      state.error = action.payload;
    },
  },
});

export const reducer = slice.reducer;
export const { searchClients } = slice.actions;

export default slice;
